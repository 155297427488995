.header {
    padding-top:0.5em;
    padding-bottom:0.5em;
    padding-left: 2em;
    padding-right:2em;
    background-color: #f4f4f4;
  }

.progress-container{
    height: 1em;
    background-color: #f4f4f4;
}

.body {
    padding-left: 2em;
    padding-right:2em;
    background-color: white;
  }

.grow {
    flex-grow: 1;
}

.margin {
    margin:1em;
}

.padding{
    padding:1em;
}
.half-margin {
    margin:0.5em;
}

.all-height {
    height: 100%;
}

.column-container {
    display: flex;
    flex-direction: column;
}

.row-container {
    display: flex;
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.flex-50{
    flex:50%;
}

.flex-60{
    flex:60%;
}

.flex-40{
    flex:40%;
}

.flex-1{
    flex:1;
}

.flex-2{
    flex:2;
}

.flex-3{
    flex:3;
}

.flex-4{
    flex:4;
}

.flex-5{
    flex:5;
}

.align-right {
    align-items: flex-end;
}

.align-end {
    align-items: flex-end;
}
.align-left {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.justify-right{
    justify-content: flex-end;
}

.justify-end{
    justify-content: flex-end;
}

.justify-start{
    justify-content: flex-start;
}

.justify-left{
    justify-content: flex-start;
}

.justify-center{
    justify-content: center;
}

.justify-space-between{
    justify-content: space-between;
}

.margin-bottom{
    margin-bottom: 1em;
}

.margin-top{
    margin-top: 1em;
}

.margin-right{
    margin-right: 1em;
}

.margin-left{
    margin-left: 1em;
}